<template>
	<div class="aptamer-favorite-container">
		<div class="item-list">
			<div class="item-container" v-for="(item, i) in records" :key="i">
				<div class="item">
					<div class="figure">
						<img :src="item.secondaryStructureFigure" />
					</div>
					<div class="location">
						<a :href="`/aptamerdb/${item.aptamerID}`" class="entry entry-title">
							{{ item.aptamerName }}
						</a>
						<div class="entry">
							<div class="label">适体类型：</div>
							<div class="value">
								{{ item.aptamerType }}
							</div>
						</div>
						<div class="entry">
							<div class="label">靶标名称：</div>
							<div class="value">
								{{ item.targetName }}
							</div>
						</div>
						<div class="entry">
							<div class="label">长 &nbsp; &nbsp; 度：</div>
							<div class="value">
								{{ item.sequenceLength }}
							</div>
						</div>
						<div class="entry">
							<div class="label">亲 和 力：</div>
							<div class="value">
								{{ item.affinity }}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="pagination">
				<el-pagination
					id="pagination"
					:current-page="pager.index"
					:page-sizes="[100, 200, 300, 400]"
					:page-size="pager.size"
					layout="prev, pager, next, jumper"
					@current-change="query"
					:total="total"
				>
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
import { ApiClient, UserCenterApi } from "../../../../api"

export default {
	data() {
		return {
			api: new UserCenterApi(ApiClient.instance),
			records: [],
			total: 0,
			pager: {
				index: 1,
				size: 100
			}
		}
	},
	mounted() {
		this.query()
	},
	methods: {
		query() {
			this.api.apiUserCenterFavoritesGet(
				0,
				{
					index: this.pager.index,
					size: this.pager.size
				},
				(e, data, resp) => {
					data = resp.body
					if (data.success) {
						this.records = data.result.records.map((item) => {
							var rec = item
							if (rec.secondaryStructureFigure) {
								rec.secondaryStructureFigure =
									process.env.VUE_APP_IMG_API + rec.secondaryStructureFigure
							}
							return rec
						})
						this.total = data.result.total
					}
				}
			)
		}
	}
}
</script>

<style lang="less" scoped>
.item-list {
	width: 100%;
	a {
		text-decoration: none;
	}

	.item-container {
		height: 200px;
		width: 100%;
		display: inline-block;
		background-color: #ffffff;
		margin-top: 16px;

		.item {
			display: flex;
			height: 176px;
			margin: 16px 23px;

			// .figure {
			// 	width: 133px;
			// 	height: 100%;
			// 	border: 1px solid;
			// 	margin: auto;
			// }

			.figure {
				width: 133px;
				height: 100%;
				border: 1px solid;
				// margin: auto;
				img {
					max-width: 131px;
					height: auto;
				}
			}

			.location {
				position: relative;
				.c-font-size-16();
				line-height: 20px;
				flex-direction: row;
				text-align: left;
				width: 100%;
				padding-left: 1rem;

				.entry {
					display: flex;
					flex-direction: row;
					margin-right: 20px;
					margin-bottom: 12px;
					color: #555555;
					font-weight: 400;
					.value {
						margin-left: 6px;
					}
				}

				.entry-title {
					font-size: 20px;
					color: #3463ea;
					margin-bottom: 20px;
				}

				.entry-cited {
					position: absolute;
					display: flex;
					top: 0rem;
					right: 0rem;
					color: #3463ea;
				}
			}
		}
	}

	.pagination {
		display: flex;
		justify-content: center;
		margin: 10px auto;
	}
}
</style>
