var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "aptamer-favorite-container" }, [
    _c(
      "div",
      { staticClass: "item-list" },
      [
        _vm._l(_vm.records, function (item, i) {
          return _c("div", { key: i, staticClass: "item-container" }, [
            _c("div", { staticClass: "item" }, [
              _c("div", { staticClass: "figure" }, [
                _c("img", { attrs: { src: item.secondaryStructureFigure } }),
              ]),
              _c("div", { staticClass: "location" }, [
                _c(
                  "a",
                  {
                    staticClass: "entry entry-title",
                    attrs: { href: `/aptamerdb/${item.aptamerID}` },
                  },
                  [_vm._v(" " + _vm._s(item.aptamerName) + " ")]
                ),
                _c("div", { staticClass: "entry" }, [
                  _c("div", { staticClass: "label" }, [_vm._v("适体类型：")]),
                  _c("div", { staticClass: "value" }, [
                    _vm._v(" " + _vm._s(item.aptamerType) + " "),
                  ]),
                ]),
                _c("div", { staticClass: "entry" }, [
                  _c("div", { staticClass: "label" }, [_vm._v("靶标名称：")]),
                  _c("div", { staticClass: "value" }, [
                    _vm._v(" " + _vm._s(item.targetName) + " "),
                  ]),
                ]),
                _c("div", { staticClass: "entry" }, [
                  _c("div", { staticClass: "label" }, [_vm._v("长     度：")]),
                  _c("div", { staticClass: "value" }, [
                    _vm._v(" " + _vm._s(item.sequenceLength) + " "),
                  ]),
                ]),
                _c("div", { staticClass: "entry" }, [
                  _c("div", { staticClass: "label" }, [_vm._v("亲 和 力：")]),
                  _c("div", { staticClass: "value" }, [
                    _vm._v(" " + _vm._s(item.affinity) + " "),
                  ]),
                ]),
              ]),
            ]),
          ])
        }),
        _c(
          "div",
          { staticClass: "pagination" },
          [
            _c("el-pagination", {
              attrs: {
                id: "pagination",
                "current-page": _vm.pager.index,
                "page-sizes": [100, 200, 300, 400],
                "page-size": _vm.pager.size,
                layout: "prev, pager, next, jumper",
                total: _vm.total,
              },
              on: { "current-change": _vm.query },
            }),
          ],
          1
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }